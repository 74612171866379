import React from "react"

const NewsletterForm = ({}) => (
	<section id="contact" className="contact-block">
		<div className="container">
      <header className="seperator-head text-center">
        <h2>Detalles de contacto</h2>
        <p>Completa tu nombre, email, mensaje y haz click en el botón <strong>Enviar Mensaje</strong> para comunicarte con nosotros.</p>
      </header>
      <form id="serverless-contact-form">
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <div className="form-group">
              <input id="form-name" name="Nombre" type="text" className="form-control element-block" placeholder="Su Nombre"></input>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="form-group">
              <input id="form-email" name="mail" type="email" className="form-control element-block" placeholder="Email"></input>
            </div>
          </div>
          <div className="col-xs-12">
            <div className="form-group">
              <textarea id="form-message" name="Message" className="form-control element-block" placeholder="Mensaje"></textarea>
            </div>
          </div>
        </div>
        <div className="text-center">
          <button type="submit" values="Send" className="btn btn-theme btn-warning text-uppercase font-lato fw-bold">Enviar mensaje</button>
        </div>
      </form>
      <br/>
      <div className="row">
        <div className="col-xs-12 col-sm-3">
          <div className="detail-column">
            <span className="icn-wrap no-shrink element-block">
              <img src="/images/icon12.png" alt="icon"></img>
            </span>
            <div className="descr-wrap">
              <h3 className="text-uppercase">Nuestro correo</h3>
              <p><a href="mailto:info@entrevistamotivacional.com.ar">info@entrevistamotivacional.com.ar</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
	</section>
)

export default NewsletterForm
