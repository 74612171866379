import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, withPrefix } from "gatsby"

import Footer from "../components/footer"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
				/* eslint-disable */
    render={data => (
      <>
        <div id="wrapper">
					<header id="page-header" className="page-header-stick">
						<div className="header-holder">
							<div className="container">
								<div className="row">
									<div className="col-xs-6 col-sm-3">
									</div>
									<div className="col-xs-6 col-sm-9 static-block">
										<nav id="nav" className="navbar navbar-default">
											<div className="navbar-header">
												<button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
													<span className="sr-only">Toggle navigation</span>
													<span className="icon-bar"></span>
													<span className="icon-bar"></span>
													<span className="icon-bar"></span>
												</button>
											</div>
											<div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
												<ul className="nav navbar-nav navbar-right main-navigation text-uppercase font-lato">
                          <li><a href="/">Inicio</a></li>
                          <li><a href="/#home-about">¿Qué es EM?</a></li>
                          <li><a href="/#home-team">Equipo</a></li>
                          <li><a href="/#home-courses">Capacitación</a></li>
                          <li><a href="/#home-news">Próximas Actividades</a></li>
													{/* Por ahora no hay blog. <li><a href="#">Blog</a></li> */}
                          <li><a href="/#contact">Contacto</a></li>
												</ul>
											</div>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</header>
					<main id="main">
          {children}
					</main>
          <Footer />
					<span id="back-top" class="text-center fa fa-caret-up"></span>
					<script src={withPrefix('js/jquery.js')}></script>
					<script src={withPrefix('js/plugins.js')}></script>
					<script src={withPrefix('js/jquery.main.js')}></script>
					<script src={withPrefix('js/init.js')}></script>
					<script src={withPrefix('js/contact.js')}></script>
				</div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
