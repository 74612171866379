import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Contact from "../components/contact"

export default ({data}) => {

  const course = data.markdownRemark;

  const registration_link = course.frontmatter.registration_link  ? course.frontmatter.registration_link : "#contact";
  const registration_text = course.frontmatter.registration_text  ? course.frontmatter.registration_text : "Para más información ...";
  const course_link = 
    <a href={registration_link} class="btn btn-theme btn-warning text-uppercase fw-bold">{registration_text}</a>

  return (
    <Layout>
      <div id="two-columns" className="container">
        <div className="row">
          <article id="content" className="col-xs-12 col-md-12">
            <h1 className="content-h1 fw-semi">{course.frontmatter.title}</h1>
            <header className="view-header row">
              <div className="col-xs-12 col-sm-12 d-flex">
                <div className="d-col">
                  <div className="post-author">
                    <div className="alignleft no-shrink rounded-circle">
                      <a href="#"><img src="http://placehold.it/35x35" className="rounded-circle" alt="image description"></img></a>
                    </div>
                    <div className="description-wrap">
                      <h2 className="author-heading"><a href="#">Instructor</a></h2>
                      <h3 className="author-heading-subtitle text-uppercase">{course.frontmatter.author}</h3>
                    </div>
                  </div>
                </div>
                <div className="d-col">
                  <div className="post-author">
                    <div className="alignleft no-shrink icn-wrap">
                      <i className="far fa-bookmark"></i>
                    </div>
                    <div className="description-wrap">
                      <h2 className="author-heading"><a href="#">Tipo</a></h2>
                      <h3 className={"author-heading-subtitle text-uppercase" + " " + course.frontmatter.class}>{course.frontmatter.course_type}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div className="aligncenter content-aligncenter">
              <img src={"/images" + course.frontmatter.slug + ".jpg"} alt="image description"></img>
            </div>
            <h3 className="content-h3">Descripcion</h3>
            <div dangerouslySetInnerHTML={{ __html: course.html }} />
          </article>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-12 text-center">
            { course_link }
          </div>
        </div>
      </div>
      <Contact />
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        author
        course_type
        slug
        registration_link
        registration_text
      }
    }
  }`
