
import React from "react"

const Footer = ({}) => (
		<div className="footer-area bg-dark text-gray">
			<footer id="page-footer" className="font-lato">
				<div className="container">
					<div className="row holder">
						<div className="col-xs-12 col-sm-push-6 col-sm-6">
							<ul className="socail-networks list-unstyled">
								<li><a href="https://www.facebook.com/EntrevistaMotivacionalArgentina/"><span className="fab fa-facebook"></span></a></li>
							</ul>
						</div>
						<div className="col-xs-12 col-sm-pull-6 col-sm-6">
              <p><a href="/">Entrevista Motivacional Argentina</a> | &copy; {new Date().getFullYear()}, Todos los derechos reservados</p>
						</div>
					</div>
				</div>
			</footer>
		</div>
)

export default Footer
